import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

// Traducciones generales del sitio
import translationEN from './locales/en.json';
import translationES from './locales/es.json';

// Traducciones específicas para los artículos del blog
import blogResponsiveDataEn from '../data/blogResponsiveEn.json';
import blogResponsiveDataEs from '../data/blogResponsiveEs.json';

// Mapea idiomas soportados
const languageMap = {
  "en": "en",
  "en-US": "en",
  "en-GB": "en",
  "es": "es",
  "es-ES": "es",
  "es-MX": "es",
  "es-419": "es", // Detectar variaciones como español latinoamericano
};

const getLanguage = () => {
  const detectedLang = navigator.language || "en";
  return languageMap[detectedLang] || "en";
};

const resources = {
  en: {
    translation: translationEN,
    blogResponsive: blogResponsiveDataEn, 
  },
  es: {
    translation: translationES,
    blogResponsive: blogResponsiveDataEs, 
  },
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    ns: ['translation', 'blogResponsive'], 
    defaultNS: 'translation',
    lng: getLanguage(), // ✅ Asegurar idioma correcto al iniciar
    fallbackLng: "en", // ✅ NO usar array, solo "en" como fallback
    interpolation: {
      escapeValue: false,
    },
    supportedLngs: ['en', 'es'],
    nonExplicitSupportedLngs: true,
  });

export default i18n;
